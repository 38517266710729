import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import Loading from "./Loading";

const ProtectedRoute = ({ element: Element, requiredPermission }) => {
  const { user } = useAuthContext();
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!user) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }

      try {
        const clubDetails = JSON.parse(localStorage.getItem("club_details"));
        if (!clubDetails?.club_id) {
          setIsAuthorized(false);
          setIsLoading(false);
          return;
        }

        // Get user roles for current club
        const rolesResponse = await axios.get(`/api/roles/user-roles`, {
          params: { userId: user.userId },
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });

        const clubRoles = rolesResponse.data.filter(
          (role) => role.club_id === clubDetails.club_id
        );

        // Get permissions for all roles
        const permissionsResponses = await Promise.all(
          clubRoles.map((role) =>
            axios.get(`/api/roles/role-permissions/${role.id}`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
                "Content-Type": "application/json",
              },
            })
          )
        );

        // Combine all permissions
        const allPermissions = new Set();
        permissionsResponses.forEach((response) => {
          response.data.forEach((p) => allPermissions.add(p.permission));
        });

        setIsAuthorized(allPermissions.has(requiredPermission));
      } catch (error) {
        console.error("Authorization check failed:", error);
        setIsAuthorized(false);
      }
      
      setIsLoading(false);
    };

    checkAuthorization();
  }, [user, requiredPermission]);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isAuthorized && !user.role == "club_manager") {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Element />;
};

export default ProtectedRoute;